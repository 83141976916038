/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "../link/link"

const NewsItem = ({
  image,
  alt,
  imageStyles,
  text,
  attribution,
  to,
  isPortrait = false,
}) => (
  <div
    sx={{
      bg: "bgGray",
      px: ["2.4rem", null, "3rem"],
      pt: ["4rem", null, "5rem"],
      pb: "4rem",
      borderRadius: "1.6rem",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <div
      className="scaleHeight scaleMarginBottom"
      sx={{
        "--min-height": [6.5, 6.5, 6.5],
        "--max-height": [6.5, 6.5, 12.5],
        "--min-mb": [2.4, 2.4, 2.4],
        "--max-mb": [2.4, 2.4, 5],
        maxWidth: "80%",
        ...imageStyles,
      }}
    >
      <GatsbyImage
        image={image}
        alt={alt}
        objectFit="contain"
        objectPosition="left"
        sx={{
          maxHeight: "100%",
          borderRadius: isPortrait ? "50%" : "none",
          aspectRatio: isPortrait ? "1" : undefined,
        }}
      />
    </div>
    <div
      className="scaleText"
      sx={{
        "--min-font-size": [1.4, 1.4, 1.4],
        "--max-font-size": [1.4, 1.4, 2],
        lineHeight: 1.5,
        mb: ["2.4rem", null, "4rem"],
      }}
    >
      {to ? (
        <Link to={to} target="_blank">
          {text}
        </Link>
      ) : (
        text
      )}
    </div>
    {attribution && (
      <div
        sx={{
          mt: "auto",
          fontSize: ["0.9rem", null, "1.2rem"],
          lineHeight: 1.5,
          letterSpacing: ["0.64px", "0.86px"],
        }}
      >
        <div sx={{ fontWeight: 600, textTransform: "uppercase" }}>
          {attribution.name}
        </div>
        {attribution.title && (
          <div sx={{ fontWeight: 300 }}>{attribution.title}</div>
        )}
      </div>
    )}
  </div>
)

NewsItem.propTypes = {
  attribution: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
}

export default NewsItem
